import { Controller } from 'stimulus'
import { isCalendlyEvent } from '../../utils'

export default class extends Controller {
  // link affiliate lead submission with the scheduled calendly appointment
  submitCalendlyForm(e) {
    // if affiliate lead submission id is not blank then link the affiliate lead submission ID and calendly appointment
    // that was just made. this work is more difficult than necessary because of calendly's poor api - the work is
    // done in the rails controller
    if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
      // set form data
      document.getElementById('calendly_scheduled_payload').value = JSON.stringify(e.data.payload)
      // submit the form
      document.getElementById('affiliates-calendly-submit-form').submit()
    }
  }
}
