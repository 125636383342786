import { Controller } from 'stimulus'
export default class extends Controller {
  toggle() {
    let isExpanded = this.element.getAttribute('aria-expanded') === 'false'
    // expect a single id or space delimited list of ids
    this.element.getAttribute('aria-controls').split(' ').forEach(item => {
      let target = document.getElementById(item)
      if (target != null) {
        target.setAttribute('aria-expanded', isExpanded)
      }
    })
    // also toggle self
    this.element.setAttribute('aria-expanded', isExpanded)
  }
}
